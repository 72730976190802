import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ROUTES from "../../config/routes";
import ErrorPage from "../../pages/Error";
import FormSignupPage from "../../pages/FormSignup";
import HomePage from "../../pages/Home";
import SuccessPage from "../../pages/Success";
import { AuthRoute } from "./AuthRoute";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute path={ROUTES.HOME} exact component={HomePage} />
        <AuthRoute
          path={ROUTES.FORM_SIGNUP + "/:name"}
          component={FormSignupPage}
        />
        <Route path={ROUTES.SUCCESS} component={SuccessPage} />
        <Route path={ROUTES.ERROR} component={ErrorPage} />
        <Redirect path="*" to={ROUTES.ERROR} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
