import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import React from "react";
import "reflect-metadata";
import Routes from "./components/Routes";
import { FormSignupProvider } from "./contexts/FormSignupContext";
import { IoCProvider } from "./contexts/IoCContext";
import { LinkValidatorProvider } from "./contexts/LinkContext";
import appContainer from "./ioc/inversifyConfig";
import "./styles/main.scss";

function App() {
  return (
    <ThemeProvider
      theme={createMuiTheme({
        typography: {
          htmlFontSize: 10,
        },
        palette: {
          primary: { main: "#D42E29" },
        },
      })}
    >
      <IoCProvider serviceContainer={appContainer}>
        <SnackbarProvider autoHideDuration={5000}>
          <LinkValidatorProvider>
            <FormSignupProvider>
              <Routes />
            </FormSignupProvider>
          </LinkValidatorProvider>
        </SnackbarProvider>
      </IoCProvider>
    </ThemeProvider>
  );
}

export default App;
