import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error: boolean;
  helperText: string;
  label?: string;
}

const Input: React.FC<Props> = ({ label, error, helperText, ...rest }) => {
  return (
    <div className="input-style">
      {label && <label>{label}</label>}
      <input {...rest} />
      {error && <span className="error-input">{helperText}</span>}
    </div>
  );
};

export default Input;
