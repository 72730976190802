import React from "react";
import { useHistory } from "react-router-dom";
import Error from "../../assets/404.png";

const ErrorPage: React.FC = () => {
  const history = useHistory();
  return (
    <div className="error-page">
      <div className="first-column">
        <h2>
          <span >Opss! Essa página <br/>não foi encontrada :/</span>
          <br/>
           O link de indicação não é válido. Peça outro link ao seu amigo.
           <br />
           <button onClick={history.goBack}>Volte à página anterior</button>
        </h2>  
      </div>
      <div className="image">
        <img src={Error} alt="Tela Aplicativo"/>
      </div>
    </div>
  );
};

export default ErrorPage;