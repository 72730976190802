import React, { createContext, useContext } from "react";
import { IIoCContext } from "./IIoCContext";

const IoCContext = createContext<IIoCContext>({} as IIoCContext);

const IoCProvider: React.FC<IIoCContext> = ({
  children,
  serviceContainer: appContainer,
}) => {
  return (
    <IoCContext.Provider
      value={{
        serviceContainer: appContainer,
      }}
    >
      {children}
    </IoCContext.Provider>
  );
};

const useIoCContext = () => {
  const context = useContext(IoCContext);
  if (!context) {
    throw new Error("useIoCContext deve ser utilizado dentro de IoCProvider");
  }
  return context;
};

export { IoCProvider, useIoCContext };
