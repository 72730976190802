import { inject, injectable } from "inversify";
import { TYPES } from "../../../ioc/TYPES";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { ICreateUserDTO } from "../dtos/ICreateUserDTO";
import { ICreateUserService } from "../models/ICreateUserService";

@injectable()
export class CreateUserService implements ICreateUserService {
  //@ts-ignore
  @inject(TYPES.IHttpService) private _httpInstance: IHttpService;

  public async execute(data: ICreateUserDTO) {
    await this._httpInstance.post("/", data);
  }
}
