import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import Arte1Img from "../../assets/arte1.png";
// import Arte2Img from "../../assets/arte2.png";
// import BannerMockupImg from "../../assets/banner-moc-1.png";
// import ArrowRollDown from "../../components/ArrowRollDown";
import Button from "../../components/Button";
import LoadingView from "../../components/LoadingView";
import ROUTES from "../../config/routes";
import { useLinkValidatorContext } from "../../contexts/LinkContext";
import "../../styles/main.scss";
import { capitalize } from "../../utils";

import Arrow  from "../../assets/Arrow.svg"

// import Telefone from "../../assets/telefone.svg"
// import Bolsa from "../../assets/bolsa.svg"
// import Din from "../../assets/din.svg"

function HomePage() {
  const linkValidatorContext = useLinkValidatorContext();
  const [name, setName] = useState("");
  const location = useLocation();

  return (
    <div className="home-page" id="topo">
      <div className="content">
        {linkValidatorContext.name === "" ? (
          <LoadingView />
        ) : (
          <>
            <section className="try-it-poshcash">
              <h5>
                <span>{capitalize(linkValidatorContext.name, true)}</span> te indicou para{" "}
                  experimentar o <span>PoshCash</span>
                <br/>
                <a href="https://poshcash.com.br/">Saiba mais sobre a Poshcash</a> <img src={Arrow} alt="arrow-site-posh"/>
              </h5>

              

              <div className="card">
                <span>
                  Para aceitar o convite, digite seu nome no campo abaixo:
                </span>

                <input
                  placeholder="Seu nome completo"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />

                <Link
                  to={{
                    pathname: ROUTES.FORM_SIGNUP + `/${name}`,
                    search: location.search,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button style={{ textAlign: "center" }}>CONTINUAR</Button>
                </Link>
              </div>

              {/* <ArrowRollDown />  */}
            </section>

            {/* <section className="money-back-to-you">
              <img src={BannerMockupImg} alt="Tela Aplicativo" />

              <p style={{paddingRight: "233px"}}>
                <span style={{paddingBottom: "24px", paddingRight: "233px"}}>Dinheiro de volta para você!</span>
                <br/>
                Com o app da <strong>Posh</strong>, suas compras rendem cashback
                naquela sua loja preferida, e você ainda aumenta seus ganhos
                convidando mais pessoas!
              </p>
            </section>

            <section className="buy-and-make-money">

              <h2>
                <span>Comprar & ganhar</span> dinheiro de volta vai <br/>virar <span>rotina!</span>
              </h2>                
            </section> */}

            {/* <section className="buy-and-win">
              <div>
                <img src={Arte1Img} alt="arte cashback" />
              </div>
              <div>
                <h4>Compre & ganhe em 3 passos:</h4>
                <h6>
                  É super fácil resgatar seu cashback pelo App Posh ou via site.
                </h6>

                  <div style={{display: "flex", alignItems: "center", textAlign: "center", paddingRight: "170px"}}>
                    <div>
                      <img src={Bolsa} style={{backgroundPosition: "top center", paddingTop: "130px", paddingBottom: "68px"}} alt="" />
                      <h2 style={{fontSize: "32px", color: "#544840", paddingBottom: "17px" }}>
                        Compre
                      </h2>
                      <p style={{fontSize: "24px",  color: "#544840"}}>
                        Consuma nos
                         estabelecimentos 
                        parceiros Posh.
                      </p>
                    </div>
                    <div>
                      <img src={Telefone} style={{backgroundPosition: "top center", paddingTop: "130px", paddingBottom: "68px"}} alt="" />
                      <h2 style={{fontSize: "32px", color: "#544840", paddingBottom: "17px"}}>
                        Cadastre-se
                      </h2>
                      <p style={{fontSize: "24px",  color: "#544840", paddingLeft: "10px", paddingRight: "10px"}}>
                        Entre no app ou no site e cadastre 
                        o cupom fiscal.
                      </p>
                    </div>
                    <div>
                      <img src={Din} style={{backgroundPosition: "top center", paddingTop: "130px", paddingBottom: "68px"}} alt="" />
                      <h2 style={{fontSize: "32px", color: "#544840", paddingBottom: "17px"}}>
                        Receba
                      </h2>
                      <p style={{fontSize: "24px",  color: "#544840"}}>
                        Pronto! Seu 
                        cashback cai na 
                        hora!
                      </p>
                    </div>
                  </div>
              </div>

            </section> */}

          {/*
            <section className="our-partners">
              <div>
                <h2>Conheça nossos parceiros</h2>
                <h6>
                  É super fácil resgatar seu cashback pelo App Posh ou via site.
                </h6>
              </div>
            </section> */}

            {/* <section className="start-earning">
              <div>
                <h2>
                  <span>Peça seu convite </span>
                </h2>
                <p>
                  e comece a ganhar agora 
                </p>
                  <a href="#topo" style={{textDecoration: "none"}}>
                    <Button style={{ textAlign: "center", background: "#FFFFFF", color: "#EB5A2E", fontSize: "32px", paddingRight: "65px"}}> <strong>QUERO SER POSH</strong></Button>
                  </a>
                
              </div>

              <div style={{backgroundPosition: "bottom center"}}>
                <img src={Arte2Img} style={{ height: "100%", width: "100%", paddingTop: "142px"}} alt="arte cashback" />
              </div>
            </section> */}
          </>
        )}
      </div>
    </div>
  );
}

export default HomePage;
