import { validate as validateCPF } from "gerador-validador-cpf";
import * as Yup from "yup";
import { checkIsDate } from "../../utils";

export const FormSignupSchemaValidation = Yup.object().shape({
  name: Yup.string().required("Preencha seu nome").uppercase(),
  document: Yup.string()
    .test("validCPF", "Precisamos de um CPF válido", (value) =>
      value ? validateCPF(value) : false
    )
    .required("Precisamos de um CPF válido"),
  email: Yup.string()
    .email("Preencha um e-mail válido")
    .lowercase()
    .required("Preencha um e-mail válido"),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email")], "E-mail não confere")
    .lowercase()
    .required("Preencha a confirmação de e-mail"),
  documentType: Yup.string(),
  captcha: Yup.string()
    .max(4, "O captcha deve ter apenas 4 números")
    .required("Você precisa resolver o captcha"),
  birthdate: Yup.string()
    .test("validBirthdate", "Precisamos de uma data válida", (value) =>
      value ? checkIsDate(value) : false
    )
    .required("Precisamos da data do seu nascimento"),
  phone: Yup.string()
    .min(15, "Você precisa digitar uma número de telefone com DDD")
    .required("Precisamos de um telefone com DDD válido"),
  acceptTerms: Yup
    .boolean()
    .oneOf([true], "Você deve aceitar os termos para prosseguir"),
});
