import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
}

const Button: React.FC<Props> = ({ loading, children, className, ...rest }) => {
  const classes = clsx("button", className);

  return (
    <button className={classes} {...rest}>
      {children}
      {loading && (
        <CircularProgress
          style={{
            marginLeft: "3rem",
            width: "3rem",
            height: "3rem",
            color: "white",
          }}
        />
      )}
    </button>
  );
};

export default Button;
