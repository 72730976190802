import React, { createContext, useContext, useState } from "react";
import { ILinkValidatorContext } from "./ILinkValidatorContext";

const LinkValidatorContext = createContext<ILinkValidatorContext>(
  {} as ILinkValidatorContext
);

const LinkValidatorProvider: React.FC = ({ children }) => {
  const [name, setName] = useState("");
  const [validated, setValidated] = useState(false);

  return (
    <LinkValidatorContext.Provider
      value={{
        name,
        validated,
        setName,
        setValidated,
      }}
    >
      {children}
    </LinkValidatorContext.Provider>
  );
};

const useLinkValidatorContext = () => {
  const context = useContext(LinkValidatorContext);
  if (!context) {
    throw new Error(
      "useLinkValidatorContext deve ser utilizado dentro de LinkValidatorProvider"
    );
  }
  return context;
};

export { LinkValidatorProvider, useLinkValidatorContext };
