import React from "react";
import LogoPosh from "../../assets/logo-posh.png"

const LoadingView: React.FC = () => {
  return (
    <div className="loading-view">
      <img src={LogoPosh} alt="Logo Posh"/>
      <h4 style={{fontWeight: 400}}>Carregando...</h4>
      <div className="bg-working" />
    </div>
  );
};

export default LoadingView;
