import qs from "query-string";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import ROUTES from "../../config/routes";
import { useFormSignupContext } from "../../contexts/FormSignupContext";
import { useIoCContext } from "../../contexts/IoCContext";
import { useLinkValidatorContext } from "../../contexts/LinkContext";
import { TYPES } from "../../ioc/TYPES";
import { ILinkValidatorDTO } from "../../modules/LinkValidator/dtos/ILinkValidatorDTO";
import { ILinkValidatorService } from "../../modules/LinkValidator/models/ILinkValidatorService";

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
}

export const AuthRoute: React.FC<Props> = ({
  component: Component,
  ...rest
}) => {
  const ioc = useIoCContext();
  const linkValidatorContext = useLinkValidatorContext();
  const [redirectToError, setRedirectToError] = useState(false);
  const formSignupContext = useFormSignupContext();

  useEffect(() => {
    const linkValidatorService = ioc.serviceContainer.get<
      ILinkValidatorService
    >(TYPES.ILinkValidatorService);

    async function fetch() {
      try {
        if (linkValidatorContext.validated) return;
        const parsedLink = (qs.parse(
          rest.location ? rest.location.search : ""
        ) as unknown) as ILinkValidatorDTO;

        const validate = await linkValidatorService.validate(parsedLink);
        linkValidatorContext.setValidated(true);
        linkValidatorContext.setName(validate.name);

        formSignupContext.setIv(parsedLink.iv);
        formSignupContext.setReferralId(parsedLink.referral_id);
        formSignupContext.setSource(parsedLink.source);
      } catch (error) {
        setRedirectToError(true);
      }
    }
    fetch();
  }, [formSignupContext, ioc.serviceContainer, linkValidatorContext, rest]);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (redirectToError) {
          return <Redirect to={ROUTES.ERROR} />;
        }
        return <Component {...routeProps} />;
      }}
    ></Route>
  );
};
