import { Container } from "inversify";
import { ICreateUserService } from "../modules/CreateUser/models/ICreateUserService";
import { CreateUserService } from "../modules/CreateUser/services/CreateUserService";
import AxiosHttpService from "../modules/infra/http/implementations/AxiosHttpService";
import { IHttpService } from "../modules/infra/http/models/IHttpService";
import { ILinkValidatorService } from "../modules/LinkValidator/models/ILinkValidatorService";
import { LinkValidatorService } from "../modules/LinkValidator/services/LinkValidatorService";
import { TYPES } from "./TYPES";

const appContainer = new Container();

appContainer.bind<IHttpService>(TYPES.IHttpService).to(AxiosHttpService);

appContainer
  .bind<ILinkValidatorService>(TYPES.ILinkValidatorService)
  .to(LinkValidatorService);

appContainer
  .bind<ICreateUserService>(TYPES.ICreateUserService)
  .to(CreateUserService);

export default appContainer;
