import Axios, { AxiosInstance } from "axios";
import { injectable } from "inversify";
import appConfig from "../../../../config/appConfig";
import AppError from "../../../../utils/AppError";
import { IHttpService } from "../models/IHttpService";

@injectable()
export default class AxiosHttpService implements IHttpService {
  //@ts-ignore
  private _httpInstance: AxiosInstance;

  constructor() {
    this._httpInstance = Axios.create({
      baseURL: appConfig.api.url,
    });
  }

  get<T = any>(path: string, params: {}) {
    return this._httpInstance
      .get<T>(path, params)
      .then(({ data }) => data)
      .catch((error) => {
        throw new AppError(error.response.data.message, "error");
      });
  }

  post<T = any>(path: string, body: any, params: {}) {
    return this._httpInstance
      .post<T>(path, body, params)
      .then(({ data }) => data)
      .catch((error) => {
        throw new AppError(error.response.data.message, "error");
      });
  }
}
