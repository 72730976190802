import React from "react";
import Arte5Img from "../../assets/arte5.png";

const SuccessPage: React.FC = () => {
  return (
    <div className="success-page">
      <div className="first-column">
        <h2>
          <span>Pronto! Suas informações foram enviadas</span>
          <br />
          Enviamos os dados de acesso para o seu e-mail. Verifique sua caixa de
          entrada.
          <p>Baixe o aplicativo clicando abaixo:</p>
          <div className="store">
            <a href="https://play.google.com/store/apps/details?id=com.voldemort">
              {" "}
              <img
                src="https://logodownload.org/wp-content/uploads/2017/04/disponivel-google-play-badge-5.png"
                alt="Logo google play store"
              />
            </a>
            <a href="https://apps.apple.com/br/app/poshcash/id1558470849">
              {" "}
              <img
                src="https://logodownload.org/wp-content/uploads/2017/05/disponivel-na-app-store-botao-8.png"
                alt="Logo google apple store"
              />
            </a>
          </div>
        </h2>
      </div>
      <div className="image">
        <img src={Arte5Img} alt="Tela Aplicativo" />
      </div>
    </div>
  );
};

export default SuccessPage;
