import { inject, injectable } from "inversify";
import { TYPES } from "../../../ioc/TYPES";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { ILinkValidatorDTO } from "../dtos/ILinkValidatorDTO";
import { ILinkValidatorService } from "../models/ILinkValidatorService";

@injectable()
export class LinkValidatorService implements ILinkValidatorService {
  //@ts-ignore
  @inject(TYPES.IHttpService) private _httpInstance: IHttpService;

  async validate(data: ILinkValidatorDTO) {
    const validateLink = await this._httpInstance.post<{ name: string }>(
      "/validator",
      data
    );

    return validateLink;
  }
}
