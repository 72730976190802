import React, { createContext, useContext, useState } from "react";
import { IFormSignupContext } from "./IFormSignupContext";

const FormSignupContext = createContext<IFormSignupContext>(
  {} as IFormSignupContext
);

const FormSignupProvider: React.FC = ({ children }) => {
  const [source, setSource] = useState("");
  const [iv, setIv] = useState("");
  const [referral_id, setReferralId] = useState("");
  const [name, setName] = useState("");

  return (
    <FormSignupContext.Provider
      value={{
        user: {
          name,
          birthdate: "",
          document: "",
          gender: "X",
          source: source,
        },
        root_id: {
          iv,
          referral_id,
        },

        setSource,
        setIv,
        setReferralId,
        setName,
      }}
    >
      {children}
    </FormSignupContext.Provider>
  );
};

const useFormSignupContext = () => {
  const context = useContext(FormSignupContext);
  if (!context) {
    throw new Error(
      "useFormSignupContext deve ser utilizado dentro de FormSignupProvider"
    );
  }
  return context;
};

export { FormSignupProvider, useFormSignupContext };
