import moment from "moment";

export const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );

export const maskCPF = (CPF: string) => {
  CPF = CPF.replace(/\D/g, "");
  let masked = CPF.length > 11 ? CPF.slice(0, 11) : CPF;
  masked = masked.replace(/^(\d{3})(\d)/, "$1.$2");
  masked = masked.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
  masked = masked.replace(/(\d{3})(\d)/, "$1-$2");
  return masked;
};

export function maskCNPJ(CNPJ: string) {
  CNPJ = CNPJ.replace(/\D/g, "");
  let masked = CNPJ.length > 14 ? CNPJ.slice(0, 14) : CNPJ;
  masked = masked.replace(/^(\d{2})(\d)/, "$1.$2");
  masked = masked.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  masked = masked.replace(/\.(\d{3})(\d)/, ".$1/$2");
  masked = masked.replace(/(\d{4})(\d)/, "$1-$2");
  return masked;
}

export function documentMask(document: string): string {
  document = document.replace(/\D/g, "").substring(0, 14);
  if (document.length > 11) {
    return maskCNPJ(document);
  }
  return maskCPF(document);
}

export function performBRPhoneMask(phone: string) {
  phone = phone.replace(/[\D|\s]/g, "").substring(0, 11);
  phone = phone.replace(/^(\d{2})(\d)/, "($1) $2");
  phone = phone.replace(/^\((\d{2})\) (\d{5})(\d)/, "($1) $2-$3");
  return phone;
}

export function checkIsDate(date: string) {
  const m = moment(date, "DD/MM/YYYY");
  if (
    !m.isValid() ||
    parseInt(m.format("YYYY")) < 1900 ||
    parseInt(m.format("YYYY")) > 2022
  ) {
    return false;
  } else return true;
}

export function performDateMask(i: string): string {
  const toProcess = i.replace(/\D+/g, "").substring(0, 8);
  if (toProcess.length > 2) {
    if (toProcess.length > 4) {
      return `${toProcess.substring(0, 2)}/${toProcess.substring(
        2,
        4
      )}/${toProcess.substring(4)}`;
    } else {
      return `${toProcess.substring(0, 2)}/${toProcess.substring(2)}`;
    }
  }
  return toProcess;
}
