/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import ROUTES from "../../config/routes";
import { useFormSignupContext } from "../../contexts/FormSignupContext";
import { maskCPF, performBRPhoneMask, performDateMask } from "../../utils";
import AppError from "../../utils/AppError";
import { FormSignupSchemaValidation } from "./FormSignupSchemaValidation";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      inputPropsFlex: {
        fontSize: "1.6rem",
        [theme.breakpoints.up("sm")]: { fontSize: "2rem" },
      },
      inputTextSize: {
        [theme.breakpoints.up("lg")]: { fontSize: "1.8rem" },
      },
      captcha: {
        maxWidth: 275,
        marginTop: "0.8rem",
        marginBottom: "4rem",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "2.4rem",
          marginLeft: "2.4rem",
          marginRight: "2.4rem",
          maxWidth: "100%",
        },
      },
      textCaptcha: {
        //@ts-ignore
        fontWeight: typography.fontWeightBold,
        fontSize: "1.4rem",
        color: theme.palette.secondary.main,
        [theme.breakpoints.up("lg")]: { fontSize: "1.8rem" },
      },
    })
);

export interface ISubmitCreateUser {
  user: {
    name: string;
    document: string;
    email: string;
    source: string;
    phone: string;
    birthdate: string;
  };
  captcha: string;
  token: string;
  root_id: {
    referral_id: string;
    iv: string;
  };
}

export const fetchCaptchaCreateUser = async () => {
  const response = await axios.get(
    "https://wrapper.poshcash.com.br/rest/referral/preflight",
    { responseType: "arraybuffer" }
  );
  const image = Buffer.from(response.data, "binary").toString("base64");

  return { token: response.headers["x-fingerprint"], image };
};

export const submitCreateUser = async ({
  token,
  ...prop
}: ISubmitCreateUser) => {
  await axios.post(
    "https://wrapper.poshcash.com.br/rest/referral",
    { ...prop },
    { params: { fp: token } }
  );
};

const FormSignupPage: React.FC = () => {
  const classes = useStyles();
  const routeParams = useParams<{ name: string }>();
  const formSignupContext = useFormSignupContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [captcha, setCaptcha] = useState({ token: "", image: "" });
  const [, setLoadingCaptcha] = useState(true);

  const fetchCaptchaCallBack = useCallback(async () => {
    try {
      setLoadingCaptcha(true);
      let image = await fetchCaptchaCreateUser();
      setCaptcha(image);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, {
          variant: error.variant,
        });
      }
      return enqueueSnackbar(
        "Ops, ocorreu um erro ao carregar o captcha, tente novamente.",
        {
          variant: "error",
        }
      );
    } finally {
      setLoadingCaptcha(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchCaptchaCallBack();
  }, [fetchCaptchaCallBack]);

  const initialValues = {
    name: routeParams.name,
    document: "",
    email: "",
    phone: "",
    acceptTerms: false,
    confirmEmail: "",
    documentType: "CPF",
    source: "link",
    root_id: "",
    captcha: "",
    token: "",
    birthdate: "",
  };

  return (
    <div className="form-signup-page">
      <div className="content">
        <div className="bg-color">
          <div className="bg-image">
            <div className="form-left-container">
              <h4>
                Falta pouco para <strong>criar sua conta na PoshCash</strong>
              </h4>
            </div>
          </div>
        </div>
        <div className="form-right-container">
          <h3>Agora complete abaixo para fazer parte da posh!</h3>

          <Formik
            onSubmit={async (values, actions) => {
              try {
                const casted = FormSignupSchemaValidation.cast(values);
                if (!casted) return;

                setLoading(true);
                await submitCreateUser({
                  user: {
                    ...values,
                    birthdate: moment(values.birthdate, "DD/MM/YYYY").format(
                      "YY-MM-DD"
                    ),
                    phone: values.phone.replace(/\D+/g, ""),
                    document: values.document.replace(/\D+/g, ""),
                  },
                  captcha: values.captcha,
                  token: captcha.token,
                  root_id: formSignupContext.root_id,
                });
                actions.resetForm();

                enqueueSnackbar("Usuário cadastrado com sucesso", {
                  variant: "success",
                });

                history.push(ROUTES.SUCCESS);
              } catch (error) {
                if (error instanceof AppError) {
                  return enqueueSnackbar(error.message, {
                    variant: error.variant,
                  });
                }
              } finally {
                setLoading(false);
              }
            }}
            initialValues={initialValues}
            validationSchema={FormSignupSchemaValidation}
          >
            {(props) => {
              return (
                <Form className="form">
                  <Grid item xs={12} lg={12}>
                    <Field
                      name="name"
                      label="Nome completo"
                      placeholder="Nome completo"
                      error={props.touched.name && !!props.errors.name}
                      helperText={props.touched.name && props.errors.name}
                      as={Input}
                    />
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <Field
                        as={Input}
                        id="birthdate"
                        name="birthdate"
                        placeholder="Ex.: 00/00/0000"
                        fullWidth
                        InputProps={{ className: classes.inputTextSize }}
                        onChange={(e: { target: { value: string } }) =>
                          props.setFieldValue(
                            "birthdate",
                            performDateMask(e.target.value)
                          )
                        }
                        className={classes.inputPropsFlex}
                        error={
                          props.touched.birthdate && !!props.errors.birthdate
                        }
                        helperText={
                          props.touched.birthdate && props.errors.birthdate
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Field
                      as={Input}
                      type="text"
                      id="document"
                      name="document"
                      placeholder="CPF"
                      fullWidth
                      InputProps={{ className: classes.inputTextSize }}
                      onChange={(e: { target: { value: string } }) =>
                        props.setFieldValue("document", maskCPF(e.target.value))
                      }
                      className={classes.inputPropsFlex}
                      error={props.touched.document && !!props.errors.document}
                      helperText={
                        props.touched.document && props.errors.document
                      }
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Field
                      as={Input}
                      type="phone"
                      id="phone"
                      name="phone"
                      placeholder="Telefone com DDD"
                      fullWidth
                      InputProps={{ className: classes.inputTextSize }}
                      onChange={(e: { target: { value: string } }) =>
                        props.setFieldValue(
                          "phone",
                          performBRPhoneMask(e.target.value)
                        )
                      }
                      className={classes.inputPropsFlex}
                      error={props.touched.phone && !!props.errors.phone}
                      helperText={props.touched.phone && props.errors.phone}
                    />
                  </Grid>

                  <Field
                    name="email"
                    placeholder="Digite seu email"
                    label="E-mail"
                    type="email"
                    error={props.touched.email && !!props.errors.email}
                    helperText={props.touched.email && props.errors.email}
                    as={Input}
                  />
                  <Field
                    name="confirmEmail"
                    placeholder="Confirme seu email"
                    label="Confirme seu email"
                    type="email"
                    autocomplete="new-password"
                    error={
                      props.touched.confirmEmail && !!props.errors.confirmEmail
                    }
                    helperText={
                      props.touched.confirmEmail && props.errors.confirmEmail
                    }
                    as={Input}
                  />
                  <Card className={classes.captcha}>
                    <CardContent>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <img src={"data:image/png;base64, " + captcha.image} />
                        {/* <ButtonReload
                          reloadFunc={fetchCaptchaCallBack}
                          reloading={loadingCaptcha}
                          style={{ padding: 0 }}
                        /> */}
                        <button
                          onClick={fetchCaptchaCallBack}
                          style={{
                            border: "none",
                            padding: "0 0.8rem",
                            backgroundColor: "#D5343D",
                            color: "white",
                          }}
                        >
                          Recarregar
                        </button>
                      </Box>

                      <Field
                        as={Input}
                        id="captcha"
                        name="captcha"
                        value={props.values.captcha}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder="Captcha"
                        className={classes.inputPropsFlex}
                        error={props.touched.captcha && !!props.errors.captcha}
                        helperText={
                          props.touched.captcha && props.errors.captcha
                        }
                      />
                    </CardContent>
                  </Card>

                  <div
                    className="acceptTerms"
                    style={{
                      justifyContent: "space-around",
                    }}
                  >
                    <Field
                      name="acceptTerms"
                      type="checkbox"
                      id="acceptTerms"
                    />
                    <label
                      htmlFor="acceptTerms"
                      style={{ color: "#414141", fontSize: "1.6rem" }}
                    >
                      Eu li, estou ciente das condições de tratamento dos meus
                      dados pessoais e dou meu consentimento, quando aplicável,
                      conforme descrito nesta&nbsp;
                      <a
                        href="https://static.poshcash.com.br/politica-de-privacidade/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <span style={{ color: "#D42E29" }}>
                          Política de privacidade.
                        </span>
                      </a>
                    </label>
                  </div>
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    {props.touched.acceptTerms && props.errors.acceptTerms}
                  </span>
                  <Button loading={loading} type="submit">
                    ENVIAR
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FormSignupPage;
